import { convertToDefaultDatetime } from "@/util"


export default class LogItemModel {
  constructor(item = {}) {
    this.ID = item.id
    this.comment = item.comment
    this.candidate = {
      ID: item.candidate && item.candidate.id ? item.candidate.id : null,
      number: item.candidate && item.candidate.number ? item.candidate.number : null,
      birthday: item.candidate && item.candidate.date_of_birth ? item.candidate.date_of_birth : null,
      firstName: item.candidate && item.candidate.first_name ? item.candidate.first_name : null,
      lastName: item.candidate && item.candidate.last_name ? item.candidate.last_name : null,
      name: item.candidate && item.candidate.name ? item.candidate.name : null
    }
    this.text = item.text
    this.createdDate = convertToDefaultDatetime(item.date_created)
    this.formattedDate = item.formatted_date
    this.status = item.status
    this.page = item.page
  }

  get fullName() {
    if (this.candidate.firstName && this.candidate.lastName) return `${ this.candidate.firstName } ${ this.candidate.lastName } (${ this.candidate.name })`
    return this.candidate.name
  }
}

import ResultCandidate from './resultCandidateModel';
import ResultReservedExam from './resultReservedExam';
import { convertToDefaultDateHourFormat, convertToDefaultHourFormat } from '@/util';
import CourseModel from '@/app/admin/models/courseModel';

export const RESERVED_EXAM = {
  ID: 'id',
  LOCATION: 'location',
  DATETIME: 'datetime',
  RESERVATION_CODE: 'reservation_code',
  PRODUCT_NAME: 'product_name',
  CANDIDATE: 'candidate',
  APPLICATION_ID: 'application_id',
  RESULT: 'result',
  IS_MIJN_RESERVATION: 'is_mijn_reservation',
  NOTES: 'notes'
};

export default class ResultModel {
  constructor(item = {}) {
    this.ID = item[RESERVED_EXAM.ID];
    this.applicationID = item[RESERVED_EXAM.APPLICATION_ID];
    this.location = item[RESERVED_EXAM.LOCATION];
    this.datetime = item[RESERVED_EXAM.DATETIME];
    this.productName = item[RESERVED_EXAM.PRODUCT_NAME];
    this.reservationCode = item[RESERVED_EXAM.RESERVATION_CODE];
    this.candidate = new ResultCandidate(item[RESERVED_EXAM.CANDIDATE]);
    this.is_mijn_reservation = item[RESERVED_EXAM.IS_MIJN_RESERVATION];
    this.result = item[RESERVED_EXAM.RESULT] ? new ResultReservedExam(item[RESERVED_EXAM.RESULT]) : null;
    this.notes = item[RESERVED_EXAM.NOTES];
    this.ignore_results = item.ignore_results;
    this.will_come_to_course = item.will_come_to_course;
    this._loading = false;
    this.color = item.color;
    this.send_result_emails = item.send_result_emails;
    this.message_sent_count = item.message_sent_count;
    this.course = new CourseModel(item.course || {});
  }

  get time() {
    if (!this.datetime) return '-';
    return convertToDefaultHourFormat(this.datetime);
  }

  get formattedDatetime() {
    if (!this.datetime) return '-';
    return convertToDefaultDateHourFormat(this.datetime);
  }

  get loading() {
    return this._loading;
  }

  set loading(val) {
    this._loading = val;
  }
}

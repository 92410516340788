import Svc from '../results-service';

export default {
  props: {
    list: Array,
    primaryPagination: Object,
    pagination: Object,
    sortModel: Object,
    listCount: Object
  },
  data: () => ({
    svc: new Svc(),
    loading: false
  }),

  computed: {
    baseProps() {
      return {
        list: this.list,
        pagination: this.pagination,
        primaryPagination: this.primaryPagination,
        loading: this.loading,
        sortModel: this.sortModel,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      };
    },
  },

  methods: {
    async _loadList({more = false, query = this.$route.query}) {
      try {
        this.loading = true
        let res = await this.svc.list({...query, tab: null})
        this.loading = false
        return res
      } catch (error) {
        console.log(error)
        this.loading = false
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    }
  }
};

export const RESULTS_TAB = {
  COME: 'come',
  NOT_COME: 'not_come'
};

export default class ResultsTabModel {
  constructor(tab, filtersModel) {
    this.filtersModel = filtersModel;
    this.value = tab || 0;
  }

  checkTabChange(val) {
    if (val === RESULTS_TAB.WILL_COME || RESULTS_TAB.NOT_COME) this.filtersModel.filters.will_come_to_course = val;
  }
}

<template lang="pug">
  .page-container.course-results
    portal(to="toolbar-title")
      toolbar-title Cursuslijsten

    v-container(fluid data-test="results-page")
      v-card
        filters(
          :listCount="listCount"
          :statistic="statistic"
          @filterChanged="loadList"
        )

        portal-target(name="list")

        v-tabs(v-model="tab" background-color="transparent").result-tab
          v-tab(:href="`#${tabs.COME}`" @click="changeTab(tabs.COME)")
            div
              div will come ({{ listCount.come }})
          v-tab(:href="`#${tabs.NOT_COME}`" @click="changeTab(tabs.NOT_COME)")
            div
              div will not come ({{ listCount.not }})

        v-tabs-items(v-model="tab")
          v-tab-item(:value="tabs.COME")
            tab-come(
              v-bind="baseListProps"
              :ref="tabs.COME"
              :sort-by.sync="sortModel.by"
              :sort-desc.sync="sortModel.desc"
              @update:sort-desc="updateSorting"
              @update:listCount="changeWillCome"
              @loadMore="loadList"
              @paginationChange="loadList"
              @loaded="updateListData"
              @loadedMore="updateListLoadMore"
              @change:count="updateCount"
            )
          v-tab-item(:value="tabs.NOT_COME")
            tab-not-come(
              v-bind="baseListProps"
              :ref="tabs.NOT_COME"
              :sort-by.sync="sortModel.by"
              :sort-desc.sync="sortModel.desc"
              @update:sort-desc="updateSorting"
              @update:listCount="changeWillCome"
              @loadMore="loadList"
              @paginationChange="loadList"
              @loaded="updateListData"
              @change:count="updateCount"
            )
        notes(
          v-if="$route.query.course"
          :key="$route.query.course ? $route.query.course : 'null'"
        )
      v-btn(
        color="primary" fab @click="loadLogs" data-test="log-btn"
        v-show="log"
      ).btn-floating
        v-icon(color="white") mdi-post
      v-navigation-drawer(
        v-model="logShowing"
        fixed
        right
        width="400"
        ref='drawer'
        v-if="log"
      )
        logs(:value="logShowing" @click:loadMore="loadLogsMore" :model="log" ref="log")
          template(v-slot:candidateName="props")
            span.link {{ props.candidate.name }}
            span.link - {{ props.candidate.number }},
</template>

<script>
import {mapGetters, mapActions} from "vuex"
import PaginationMixin from "@/mixins/pagination.mixin"

import Svc from "./core/results-service"
import errorsMixin from '@/mixins/errors.mixin'
import ResultsBroadcast from "./core/models/resultsExamBroadcast"
import { RESERVED_EXAM } from './core/models/resultModel'
import ResultReservedExam, { RESULT_STATUS, RESULT } from './core/models/resultReservedExam'
import ResultsLogModel from "./core/models/resultLogModel"
import { LOG_PAGE } from '@/util/const'
import PaginationModel from "@/models/paginationModel"
import ResultSortModel from "./core/models/resultSortModel"
import { RESULTS_TAB } from './core/models/resultsTabModel'
import tabCome from "./components/ResultsTabCome.vue"
import tabNotCome from "./components/ResultsTabNotCome.vue"

export default {
  mixins: [PaginationMixin, errorsMixin],

  data: () => ({
    svc: new Svc(),
    loading: false,
    tab: RESULTS_TAB.COME,
    tabs: RESULTS_TAB,
    list: [],
    examBroadcast: null,
    log: null,
    logShowing: false,
    primaryPagination: null,
    pagination: null,
    sortModel: {},
    listCount: {
      come: '...',
      not: '...'
    },
    statistic: null
  }),

  created() {
    this.init()
  },

  mounted() {
    this.loadColors()
  },

  computed: {
    ...mapGetters({
      currentUser: 'user/current'
    }),

    baseListProps() {
      return {
        list: this.list,
        pagination: this.pagination,
        primaryPagination: this.primaryPagination,
        sortModel: this.sortModel,
        listCount: this.listCount
      }
    }
  },

  methods: {
    ...mapActions({
      loadColors: 'crmCandidateColors/colorsList',
    }),

    init() {
      this.initExamBroadcast()
      this.initSortModel(this.$route.query)
      if (this.$route.query.tab) this.tab = this.$route.query.tab
    },

    async initExamBroadcast() {
      let tokens = await this.$auth.getTokensFromRepoOrRefresh()
      this.examBroadcast = new ResultsBroadcast(tokens.access)
      this.examBroadcast.startListen(this.examResultHandler)
    },

    async initLogs(wsURL) {
      let tokens = await this.$auth.getTokensFromRepoOrRefresh()
      this.log = new ResultsLogModel({page: [LOG_PAGE.EXAM_RESULTS]}, `${wsURL}?auth_token=${tokens.access}`)
      this.log.ws.addEventListener("message", this.listenLogs)
    },

    listenLogs(msg) {
      this.log.listen(msg)
    },

    examResultHandler(msg) {
      let itemIndex = this.list.findIndex((item) => item.ID === msg[RESERVED_EXAM.ID])
      if (itemIndex < 0) return
      for (let field in msg) {
        if (field in this.list[itemIndex]) {
          if (field === 'result')
            this.list[itemIndex].result = new ResultReservedExam(msg[RESERVED_EXAM.RESULT])
          else this.list[itemIndex][field] = msg[field]

        }
      }
      if ('result' in msg && (msg[RESERVED_EXAM.RESULT][RESULT.STATUS] === RESULT_STATUS.LOADED || msg[RESERVED_EXAM.RESULT][RESULT.STATUS] === RESULT_STATUS.FAILED))
        this.list[itemIndex].loading = false
    },

    updateCount(count) {
      this.listCount = count
    },

    updateListData(res) {
      this.pagination = new PaginationModel(this.setPaginationParams(res.pagination))
      this.list = []
      res.results.forEach((item) => setTimeout(() => this.list.push(item), 0))
      this.statistic = res.statistic
      this.setPrimaryPagination()
    },

    updateListLoadMore(res) {
      this.pagination = new PaginationModel(this.setPaginationParams(res.pagination))
      res.results.forEach((item) => setTimeout(() => this.list.push(item), 0))
    },

    async loadList() {
      this.$refs[this.tab].loadData()
    },

    loadLogs() {
      if (this.logShowing) {
        this.logShowing = false
        return
      }
      this.$refs.log.loadData({...this.$refs.log.getDefaultPaginationParams()})
      this.logShowing = true
    },

    loadLogsMore() {
      this.$refs.log.loadMore({
        ...this.$refs.log.getPaginationParams(),
      })
    },

    setPrimaryPagination() {
      this.primaryPagination = {
        page: this.pagination.page,
        size: this.pagination.size
      }
    },

    initSortModel() {
      this.sortModel = new ResultSortModel(this.$route.query)
    },

    async updateSorting() {
      let data = this.sortModel.getApiData(this.$route.query)
      let ordering
      if(data.ordering.length) ordering = data.ordering
      await this.$router.replace({ query: { ...this.$route.query, ...this.getDefaultPagination(), ordering: ordering}})
      this.loadList()
    },

    changeWillCome({ID, value, listCount}) {
      this.updateWillCome({ID, value})
        .then(() => {
          this.removeFromListBy(ID)
          this.updateCount(listCount)
        })
    },

    async updateWillCome({ID, value}) {
      try {
        return await this.svc.updateWillCome(ID, value)
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    removeFromListBy(ID) {
      let deletedIndex = this.list.findIndex(item => item.ID === ID)
      if(deletedIndex < 0) return false
      this.list.splice(deletedIndex, 1)
    },

    updateWillComeStatus(payload) {
      this.updateWillCome(payload).then(() => {
        let index = this.list.findIndex(item => item.ID === payload.ID)
        if (index < 0) return this.$notify({type: 'error', text: 'Something wrong. Refresh page'})
        this.list[index].will_come_to_course = payload.value
      })
    },

    async changeTab(key) {
      if (this.tab === key) return;
      this.list = []
      this.$router.push({query: {...this.$route.query, page: 1, tab: key}})
      await this.$nextTick()
      this.loadList()
    }
  },

  watch: {
    currentUser: {
      handler: function (newUserData) {
        if (newUserData && newUserData.logsWebsocketPath) this.initLogs(newUserData.logsWebsocketPath)
      },
      immediate: true
    }
  },

  provide: function() {
    return {
      getDefaultPagination: this.getDefaultPagination,
      svc: this.svc
    }
  },

  beforeRouteLeave(to, from , next) {
    this.examBroadcast.close()
    if(this.log && this.log.ws) {
      this.log.ws.removeEventListener("message", this.listenLogs)
      this.log.ws.close()
    }
    next()
  },

  components: {
    toolbarTitle: () => import('@/components/global/toolbar/ToolbarTitle.vue'),
    filters: () => import('./components/ResultsFilters.vue'),
    notes: () => import('./components/ResultsNotes.vue'),
    logs: () => import('@/app/admin/components/Log.vue'),
    tabCome,
    tabNotCome
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/_placeholder.scss";
@import "@/assets/scss/_table_spread";

.result-tab .v-tabs-bar.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-tabs-bar--show-arrows):not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: none;
}

.result-tab .v-tab {
  max-width: 75px;
  padding-left: 0;
  padding-right: 5px;
  min-width: 50px;
  font-size: 11px;

  @media(min-width: 768px) {
    max-width: 360px;
    font-size: 14px;
    padding: 0 16px;
  }
}
</style>

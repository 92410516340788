<template lang="pug">
  div
    list(
      v-bind="baseProps"
      @update:willCome="updateWillComeCounting"
      @update:sort-by="$emit('update:sort-by', $event)"
      @update:sort-desc="$emit('update:sort-desc', $event)"
      @loadMore="loadList($route.query, {more: true})"
      @paginationChange="loadList"
    )
</template>

<script>
import Svc from "../core/results-service"
import baseTabMixin from "../core/mixins/baseTabMixin"

export default {
  mixins: [baseTabMixin],

  methods: {
    async loadData() {
      let [willComeCount, notComeCount] = await Promise.all([
        this.loadList({...this.$route.query, will_come_to_course: true}),
        this._loadList({query: {...this.$route.query, will_come_to_course: false}}),
      ])
      this.$emit('change:count', {
        come: willComeCount.pagination.count,
        not: notComeCount.pagination.count
      })
    },

    async loadList(query = this.$route.query, options = {more: false}) {
      let res = await this._loadList({query: query})
      if (options && options.more) this.$emit('loadedMore', res)
      else this.$emit('loaded', res)
      return res
    },

    updateWillComeCounting(payload) {
      let willCome = payload.value
      let listCount = {}
      if (willCome) {
        listCount = {
          come: this.listCount.come + 1,
          not: this.listCount.not - 1
        }
      } else {
        listCount = {
          come: this.listCount.come - 1,
          not: this.listCount.not + 1
        }
      }
      this.$emit('update:listCount', {...payload, listCount})
    }
  },

  components: {
    list: () => import('./ResultsList.vue'),
  }
}
</script>

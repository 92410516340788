import { RESERVED_EXAM } from "./resultModel"

export const RESERVED_EXAM_CANDIDATE = {
  ID: 'id',
  NUMBER: 'number',
  DATE_OF_BIRTH: 'date_of_birth',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  APPLICATION_ID: 'application_id',
  NAME: 'name',
  PHONE: 'phone'
}

export default class ResultCandidate {
  constructor(candidate = {}) {
    this.ID = candidate[RESERVED_EXAM_CANDIDATE.ID]
    this.number = candidate[RESERVED_EXAM_CANDIDATE.NUMBER]
    this.DOB = candidate[RESERVED_EXAM_CANDIDATE.DATE_OF_BIRTH]
    this.firstName = candidate[RESERVED_EXAM_CANDIDATE.FIRST_NAME]
    this.lastName = candidate[RESERVED_EXAM_CANDIDATE.LAST_NAME]
    this.applicationId = candidate[RESERVED_EXAM_CANDIDATE.APPLICATION_ID]
    this.name = candidate[RESERVED_EXAM_CANDIDATE.NAME]
    this.phone = candidate[RESERVED_EXAM_CANDIDATE.PHONE]
  }
}